.background {
  padding: 1.2em;
  background: rgb(248, 248, 248, 0.4);
}
.calendar-style {
  min-width: 320px;
  height: 65vh;
}
.h-100vh {
  height: 100vh !important;
}
.modal-90w {
  max-width: 65vw !important;
  /* max-height: 30vh !important; */
  /* background: #ffffff; */
  /* box-shadow: 0px 0px 15px 6px rgba(238, 238, 238, 0.15);
  border-radius: 10px; */
}
.modal-50w {
  max-width: 50vw !important;
  /* max-height: 30vh !important; */
  /* background: #ffffff; */
  /* box-shadow: 0px 0px 15px 6px rgba(238, 238, 238, 0.15);
  border-radius: 10px; */
}
.dot {
  height: 4em;
  width: 4em;
  background-color: rgba(252, 243, 226, 1);
  border-radius: 50%;
  display: inline-block;
}
.dot-small {
  height: 1.7rem;
  width: 1.7rem;
  background-color: rgba(252, 243, 226, 1);
  border-radius: 50%;
  display: inline-block;
}
.main {
  padding: 1.2em 0 0 0;
  /* background: rgb(248, 248, 248, 0.4); */
}
.boost-container {
  padding: 1.4rem;
}

.data-box-parent {
  width: 100%;
  align-items: stretch;
}

.data-box-child {
  height: 7em;
  background: #ffffff;
  border: 1px solid #dfe0eb;
  box-sizing: border-box;
  border-radius: 8px;
  flex: 1;
  width: 30%;
}

.data-box-child:not(:last-child) {
  margin-right: 1em;
}

.data-box-child-content {
  margin: 1em;
}

.container-data-boost {
  height: 17rem;
  background: #ffffff;
  border: 1px solid #dfe0eb;
  box-sizing: border-box;
  border-radius: 8px;
  padding: 2em;
}
.container-boost-header {
  height: 40%;
}
.container-boost-body {
  height: 20%;

  text-align: center;
}
.boost-body-text {
  margin-bottom: 0.5rem;
  font-weight: 700;
  line-height: 1.2;
  font-size: 1.25rem;
}
.container-boost-footer {
  height: 25%;
}

.container-vertial-box {
  background: #ffffff;
  border-radius: 10px;
  width: 100%;
  float: right;
  margin-left: auto;
  margin-top: 1em;
}

.row-mt-space {
  margin-top: 4em !important;
}

.row-ml-space {
  margin-left: 1em !important;
}
.row-mr-space {
  margin-right: 1em !important;
}
.title {
  font-weight: 900;
}

.sh-1{
  font-size:20px;
}

.body-text {
  font-weight: 500;
}
.body-text-light {
  font-weight: 500;
  color: rgb(0, 0, 0, 0.5);
}
.div-container-style {
  height: 65vh;
  min-height: 670px;
  background: #ffffff;
}
@media (max-width: 768px) {
  .row-mr-space {
    margin-right: 0em !important;
  }
  .row-ml-space {
    margin-left: 0em !important;
  }
}

@media (min-width: 576px) {
  .boost-body-text {
    margin-bottom: 0.5rem;
    font-weight: 700;
    line-height: 1.2;
    font-size: 1rem;
  }
}

@media (min-width: 768px) {
  .boost-body-text {
    margin-bottom: 0.5rem;
    font-weight: 700;
    line-height: 1.2;
    font-size: 1rem;
  }
}

@media (min-width: 1050px) {
  .boost-body-text {
    margin-bottom: 0.5rem;
    font-weight: 700;
    line-height: 1.2;
    font-size: 1rem;
  }
}
@media (min-width: 1200px) {
  .boost-body-text {
    margin-bottom: 0.5rem;
    font-weight: 700;
    line-height: 1.2;
    font-size: 1.2rem;
  }
}

.boost-button {
  border: 3px solid transparent;
  width: 13rem;
  height: 3.5rem;
  background: #0A72C3;
  border-radius: 100px;
  outline: none;
  color: #ffffff;
  font-weight: 800;
  font-size: 1rem;
  transition: 0.3s;
}
.boost-button:active,
.boost-button:focus {
  outline: none;
}
.boost-button:hover {
  border: 3px solid rgba(165, 185, 242, 1);
}

.boost-button-zero {
  border: 3px solid transparent;
  width: 13rem;
  height: 3.5rem;
  background: rgba(252, 243, 226, 1);
  border-radius: 100px;
  outline: none;
  color: #ca9160;
  font-weight: 800;
  font-size: 1rem;
  transition: 0.3s;
}
.boost-button-zero:active,
.boost-button-zero:focus {
  outline: none;
}
.boost-button-zero:hover {
  border: 3px solid rgba(165, 185, 242, 1);
}

.boost-header-style {
  font-size: 1.7rem;
  font-weight: 400;
}

.boost-body-style {
  font-weight: 500;
  font-size: 1rem;
}
.boost-dismiss-style {
  font-weight: 600;
  font-size: 1.2rem;
  color: #0A72C3;
  cursor: pointer;
}
.boost-text-small {
  font-size: 1rem;
  font-weight: 600;
}
.boost-number-small {
  font-size: 1rem;
  font-weight: 600;
  color: #ca9160;
}
.typeform-style {
  width: 100%;
  height: 100%;
}

.checkbox-style {
  height: 1.5rem;
  min-width: 1.5rem;
  background: #ffffff;
  border: 1px solid #0A72C3;
  box-sizing: border-box;
  border-radius: 4px;
  border-width: 0.3rem;
  cursor: pointer;
}

.checkbox-incomplete {
  opacity: 1;
}
.checkbox-complete {
  opacity: 0.4;
}


.stat-title-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: right;
  height: 50%;
}

.tooltip{

  color:  #9fa2b4;
  font-size: 70%;
  width: 100px;
  background-color: '#9fa2b4';
  text-align: center;
  border-width: 1px;
  border-radius: 5px;
  border: 1px solid #cbcdda;
  padding: 3px;
}


/* For the arrow at the bottom of the tooltip */
.tooltip::after{
  content: " ";
  position: inherit;
  top: 100%; /* At the bottom of the tooltip */
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: #9fa2b4 transparent transparent transparent;

}

/** LHT page styles */
.lht-title{
  font-weight: bold;
  font-size: 41px;
  line-height: 21px;
}

.lht-subtitle {
  font-size: 20px;
  line-height: 21px;
  margin: 20px 0 20px 0;
}

.hashtag-button {
  border: none;
  background: none;
  outline: none;
  margin:auto;
  font-weight: 500;
  padding:7px;
  color:#0A72C3;
  font-style: italic;
}

.hashtag-item-selected {
  display:flex;
  flex-direction: row;
  margin:10px;
  background: #0A72C3;
  border-radius: 30px;
  height: 40%;
  width:25%;
  flex:10% 0;
}

.hashtag-button-selected {
  border: none;
  background: none;
  outline: none;
  margin:auto;
  font-weight: 500;
  padding:7px;
  color:white;
  font-style: italic;
}

.hashtag-button:active, .hashtag-button:focus {
   outline: none;
}

.hashtag-item {
  display:flex;
  flex-direction: row;
  margin:10px;
  color:#0A72C3;
  border-radius: 30px;
  height: 40%;
  width:25%;
  flex:10% 0;
}

.hashtag-container{
  width: 100%;
  display: flex;
  flex-wrap: wrap;
}

.hashtag-container-none {
 margin:auto;
 color:rgba(33, 46, 72, 0.6);
}

.chart-container {
  width:100%;
  height:500px;
}

.cta-container {
  width:950px;
  background-color: #0A72C3;
  border-radius: 10px;
  padding:10px;
  margin-bottom:25px;
  color: white;
  font-size: 18px;
  font-weight:500;
}

.special-cta-container {
  width:950px;
  background-color: rgb(227, 172, 122);
  border-radius: 10px;
  padding:10px;
  margin-bottom:25px;
  color: white;
  font-size: 18px;
  font-weight:500;
}

.cta > a{
  color: rgb(227, 172, 122) !important;
}

.special-cta > a {
  color: #0A72C3 !important;
}


.hide {
  visibility: hidden;
}

.w-950{
  width:950px;
}

/** Social share */
.hover-container{
  width: 250px;
  padding:9px;
  border: none;
  border-radius: 6px;
  background-color: #ffffff;
  font-size: 12px;
  box-shadow: 0px 0px 6px rgb(0 0 0 / 10%);
}

.hover-container::after{
  content: " ";
  position: inherit;
  top: 100%; /* At the bottom of the tooltip */
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: white transparent transparent transparent;

}

/**announcement page*/
.announcement-container{
  background-color: #E5F0FF;
  border: none;
  border-radius: 10px;
  padding:25px;
  margin-bottom:25px;
}

.font-grey {
  color:#212e489e;
}

.cta-button{
  border: none;
  background: #0A72C3;
  border-radius: 40px;
  color: #ffffff;
  padding:20px;
  font-weight: 700;
  font-size: 17px;
}

/** Display allow notification dialog box */
.notification {
  color: #0A72C3;
}

.notification:hover{
  cursor: pointer;
  text-decoration: underline;
}

/** Hashtag */
textarea:focus, input:focus{
  outline: none;
}

.text-input{
  border-radius: 6px;
  color: #0A72C3;
  font-size: 16px;
  border: 0;
  width: 100%;
  height: 23px;
}

.button{
  background: #0A72C3;
  border-radius: 30px;
  font-size: 15px;
  color: white;
  text-align: center;
  height: 37px;
  border-style: solid;
  border: none;
  width: max-content;
  font-weight: 500;
  padding:12px 20px;
  gap:8px
}

::placeholder {
  font-style: italic;
  color: #99A1AE;
}

.spin{
  margin-right: 7px;
  top: 2px;
  animation: spin 3s infinite linear
}

@keyframes spin {
  from {
      transform: scale(1) rotate(0deg);
  }
  to {
      transform: scale(1) rotate(360deg);
  }
}