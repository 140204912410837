.background {
  padding: 0 0 1em 3em;;
}
.title {
  font-weight: 700;
    font-size: 32px;
    line-height: 48px;
    color: #2F3EAC;
}
.main {
  padding: 1.2em 1.2em 0em 1.2em;
  background: rgb(248, 248, 248, 0.4);
}
.p-12 {
  padding: 1.2em;
}
.content-margin-top {
  margin-top: 4rem;
}

.card-style {
  background: #fff;
  border-radius: 10px;
  flex: 1 1 auto;
}

.box {
  display: flex;
  flex-flow: column;
  height: 100%;
}

.box .content {
  flex: 1 1 auto;
}
.box-text {
  font-size: 1.5rem !important;
  color: #000000;
  font-weight: 800;
  margin-right: 0rem;
}

.lesson-background {
  border-radius: 10px;
  padding: 20px 12px;
  border-style: solid;
  border-color: #F2F6FA;
  border-width: 4px;
  margin-bottom:15px;
}

.box-yellow {
  background: #fcf3e2 !important;
  padding: 0rem 1rem;
  border-radius: 10px;
  width: 100%;
  height: 6rem;
  border-style: solid;
  border-color: #fcf3e2;
  border-width: 1px;
}

.box-black {
  background: #fff !important;
  padding: 0.5rem 2rem;
  border-radius: 10px;
  width: 100%;
  height: 5.5rem;
  border-style: solid;
  border-color: #000;
  border-width: 1px;
}

.dot {
  height: 4em;
  width: 4em;
  background-color: rgb(226, 252, 228);
  border-radius: 50%;
  display: inline-block;
}
.complete {
  background-color: #75ca60;
}
.incomplete {
  background-color: #ca9160;
}
.dot-small {
  height: 1.7rem;
  width: 1.7rem;
  border-radius: 50%;
  display: inline-block;
}
.body-text-title {
  font-weight: 800;
  font-size: 1rem;
}
.body-text-content {
  font-size: 1rem;
  line-height: 1.2rem;
}
.negative-margin {
  margin-left: -1.5rem;
  margin-right: -1.5rem;
}
