.background {
  padding: 1.2em;
  background: rgb(248, 248, 248, 0.4);
  height: 100%;
}
.button-spacer {
  margin-top: 2.1rem;
}
.main {
  padding: 1.2em;
  background: rgb(248, 248, 248, 0.4);
}
.p-12 {
  padding: 1.2em;
}
table .w-5 {
  width: 5%;
}
.table-background {
  background: #ffffff !important;
}
.title {
  font-weight: 900;
}
.table-header > th {
  font-weight: 800;
  font-size: 1.2em;
}
.div-status {
  width: 5.5em;
  padding: 0.3em;
}
.row-name-style {
  font-size: 1.5em;
  font-weight: 400;
}
.div-pending-color {
  background: #fcf3e2;
  border-radius: 4px;
  color: #ca9160;
}
.div-complete-color {
  background: #44b480;
  border-radius: 4px;
  color: #ffffff;
}

.checkbox-style {
  height: 1.5em;
  width: 1.5em;
  background: #ffffff;
  border: 1px solid #0A72C3;
  box-sizing: border-box;
  border-radius: 4px;
  border-width: 0.3em;
  cursor: pointer;
}
.checkbox-incomplete {
  opacity: 1;
}
.checkbox-complete {
  opacity: 0.4;
}
.form-input {
  background: #F2F6FA;;
  border: none;
  box-sizing: border-box;
  border-radius: 200px;
  height: 56px;
}

.submit-button {
  border: 3px solid transparent;
  width: 100%;
  padding: 21px;
  background: #0A72C3;
  border-radius: 100px;
  height: 4rem;
  color: white;
  transition: 0.3s;
}
.submit-button:focus {
  outline: none;
}

.return-button {
  border: 0px;
  width: 100%;
  padding: 0;
  background: white;
  border-radius: 100px;
  height: 3em;
  color: black;
}

.logo {
  width: 23%;
  height: auto;
}

.form-style {
  background: #fff;

  flex: 1 1 auto;
}

.box {
  display: flex;
  flex-flow: column;
  height: 100%;
}

.box .content {
  flex: 1 1 auto;
}

.link-highlighted {
  border: 3px solid transparent;
  background: #fcf3e2 !important;
  padding: 1em 1em;
  border-radius: 10px;
  font-size: 0.8rem !important;
  font-weight: 900;
  margin-right: 0rem;
  transition: linear 0.2s;
  outline: none;
  border-radius: 100px;
}
.link-highlighted:focus {
  outline: none;
}
.link-highlighted:hover {
  border: 3px solid rgba(165, 185, 242, 1);
}

.unselected-plan {
  background: #F2F6FA;
}

.selected-plan {
  background: #0A72C3;
  color:white;
}

.select-button {
  background: #0A72C3;
  color:white;
  padding:8px 6px;
  border-radius: 4px;
  margin: 10px 0;
  cursor: pointer;
}

.button {
  color: #0A72C3;
  background:white;
  padding:8px 6px;
  border-radius: 4px;
  margin: 10px 0;
  cursor: pointer;
}
