.h-35 {
  height:35px;
}

.w-100 {
    width:100%;
}

.h-100 {
    height:100px;
}

.row {
    display: flex;
    flex-direction: row;
}

 .row-item{
    flex : 1 1 auto;
}

.column{
  display: flex;
  flex-direction: column;
}



.text-input{
    background-color: rgba(56, 90, 159, 0.1);
    border-radius: 6px;
    color:#385a9f;
    font-size: 14px;
    border: 0;  
  }

  .text-input::placeholder{
    color:#385a9fb2;
  }

  .button{
    width: 100px;
    border-radius: 30px;
    background-color: white;
    font-size: 14px;
    color: #385A9F;
    text-align: center;
    height: 30px;
    border-style: solid;
    border-color: #385A9F;
  }
  
  .dropdown{
    z-index: 1000;
    position: absolute;
    margin-top: 26px;
    background-color: white;
    width: 39%;
    border-radius: 0px 0px 10px 10px;
    padding: 10px;
    border: 2px;
    border-style: none solid solid;
    border-color: #385A9F;
  }

  .dropdown-row {
    color:#385a9f;
    font-weight:500;
    cursor: pointer;
    padding:3px;
    border-radius: 5px;
  }

  .dropdown-row:hover {
    background-color: rgba(56, 90, 159, 0.3);
  } 

