.main {
    height: 100vh !important;
}

.row {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

.rightbackground {
    background: #FFFFFF;
    /* background: #c4c4c4; */
}

.leftbackground {
    background: #c4c4c4;
}

.leftbackground2 {
    background: #FFFFFF;
}

.register-button {
    border: 3px solid transparent;
    width: 15%;
    padding: 0;
    background: #22314F;
    border-radius: 100px;
    height: 3rem;
    color: white;
    outline: none;
    transition: 0.3s;
}

.fcolumn {
    display: flex;
    flex-direction: column;
  }

  .rect-button {
    padding:10px;
    border: 1.25463px solid rgba(51, 51, 52, 0.4);
    border-radius: 10px;
    box-shadow: 0 4px 6px -1px rgb(0 0 0 / 10%), 0 2px 4px -2px rgb(0 0 0 / 10%);
    width:max-content;
    margin:auto;
  }
  
  