.form-input {
  background: #f3f8ff;
  border: 1px solid rgba(46, 89, 221, 0.4);
  box-sizing: border-box;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.15);
  border-radius: 100px;
  height: 4rem;
}

.form-input2 {
  background: #2e59dd1a;
  border-style: none;
  box-sizing: border-box;
  border-radius: 12px;
  height: 4rem;
}

.form-input3 {
  background: #F0F2F4;
  border-style: none;
  box-sizing: border-box;
  border-radius: 200px;
  height: 36px;
  box-shadow: inset 0px 1px 2px rgba(0, 0, 0, 0.1);
}

.form-input3::placeholder {
  color: #00000075;
}

input {
  padding: 10px;
  -webkit-box-sizing: border-box; /* Safari/Chrome, other WebKit */
  -moz-box-sizing: border-box;    /* Firefox, other Gecko */
  box-sizing: border-box;         /* Opera/IE 8+ */
}

::placeholder{
  color: #2F3EAC;
}

.submit-button {
  border: 3px solid transparent;
  width: 100%;
  padding: 0;
  background: #2F3EAC;
  border-radius: 100px;
  height: 4rem;
  color: white;
  outline: none;
  transition: 0.3s;
  text-align: center;
}
.submit-button2 {
  border: 3px solid transparent;
  width: 100%;
  padding: 0;
  background: #2F3EAC;;
  border-radius: 100px;
  height: 4rem;
  color: white;
  outline: none;
  transition: 0.3s;
}
.submit-button:focus {
  outline: none;
}
.submit-button:hover {
  border: 3px solid rgba(165, 185, 242, 1);
}
.submit-button:disabled {
  background: #9FACCC;
}
.return-button {
  border: 0px;
  width: 100%;
  padding: 0;
  background: white;
  border-radius: 100px;
  height: 3em;
  color: black;
}

.logo {
  width: 30%;
  height: auto;
}

.rect-button {
  padding:10px;
  border: 1.25463px solid rgba(51, 51, 52, 0.4);
  border-radius: 10px;
  box-shadow: 0 4px 6px -1px rgb(0 0 0 / 10%), 0 2px 4px -2px rgb(0 0 0 / 10%);
  width:max-content;
  margin:auto;
}
