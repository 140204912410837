.loader {
  width: 112px;
  height: 112px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

.circle {
  position: absolute;
  width: 56px;
  height: 56px;
  border-radius: 100%;
  background: var(--color-brand);
  animation: radar 3s ease-out infinite;
  box-shadow: 0px 0px 10px #3947a80d;
}

.circle:nth-of-type(1) {
  animation-delay: 0.2s;
}

.circle:nth-of-type(2) {
  animation-delay: 1s;
}

.iconBox {
  background: var(--color-brand);
  height: 56px;
  width: 56px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  position: absolute;
  z-index: 2;
}

.searchIcon {
  height: 32px;
  width: 32px;
  color: white;
}

@keyframes radar {
  0% {
    width: 56px;
    height: 56px;
    opacity: 0.5;
  }
  30% {
    width: 84px;
    height: 84px;
    opacity: 0.3;
  }
  100% {
    width: 112px;
    height: 112px;
    opacity: 0;
  }
}
