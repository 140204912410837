/* @import "../Login/LoginPage.module.css";
@import "../Login/FormInput.module.css"; */

.main {
  background-color: #0A72C3;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  max-width: 245px;
  font-size: 1.5em;
  overflow-x: hidden;
  color: #000000;
  -ms-flex: 0 0 95.666667%;
  flex: 0 0 95.666667%;
  position: relative;
  width: 100%;
  padding: 0.8em 1.4em !important;
  box-shadow: 1px 0px 4px #eff3f9;
}

.main2 {
  background-color: #ffffff;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  max-width: 320px;
  font-size: 1.5em;
  overflow-x: hidden;
  color: #000000;
  -ms-flex: 0 0 95.666667%;
  flex: 0 0 95.666667%;
  position: relative;
  width: 100%;
  padding: 0.8em 1.4em !important;
  box-shadow: 1px 0px 4px #eff3f9;
}

.rec16{
  border: 1px solid #0A72C3;
  box-sizing: border-box;
  border-radius: 15px;
}

.rec17{
  border: 1px solid #ffffff;
  box-sizing: border-box;
  border-radius: 15px;
  padding: 1rem;
}

.rec18{
  border: 2px solid #ffffff;
  box-sizing: border-box;
  border-radius: 15px;
  padding: 1rem 0.5rem;
}

.submit-button {
  border: 3px solid transparent;
  width: 100%;
  padding: 1em 1em;
  background: #385A9F;
  border-radius: 100px;
  outline: none;
  color: #ffffff;
  font-weight: 800;
  font-size: 1rem;
  transition: linear 0.2s;
}

.cta-button {
  border: 3px solid transparent;
  width: 100%;
  padding: 1em 1em;
  background: #0A72C3;
  border-radius: 100px;
  outline: none;
  color: #ffffff;
  font-weight: 550;
  font-size: 18px;
  transition: linear 0.2s;
}

.submit-button:focus, .cta-button:focus {
  outline: none;
}
.submit-button:hover, .cta-button:hover {
  border: 3px solid rgba(165, 185, 242, 1);
}

img.img-sidebar-logo {
  width: 53%;
  height: auto;
}

.link-container{
  height: 50px;
  display: flex;
  align-items: center;
  padding:10px;
}

.link-container:hover{
  background: rgba(255, 255, 255, 0.25);
  border-radius: 8px;
}

.link-text {
  font-size: 12px;
  line-height: 24px;
  color: white;
}

.link-text2 {
  font-size: 16px;
  line-height: 24px;
  color: white;
}

.link-text3 {
  color: rgba(33, 46, 72, 0.6);
  font-weight: bold;
  font-size: 14px;
  cursor: pointer;
}

.link-text4 {
  font-size: 11px;
  line-height: 24px;
  color: white;
}

.help-center {
  font-weight: bold;
  font-size: 18px;
  color:#385A9F;
}

.box-text {
  font-size: 0.8em !important;
  color: #000000;
  font-weight: 900;
  margin-right: 0rem;
}

.box-yellow {
  background: #fcf3e2 !important;
  padding: 0.5em 0em;
  border-radius: 10px;
  width: 100%;
}

.leftbackground {
  background: #c4c4c4;
}

.refer-main {
  background: #f3f8ff;
  border-radius: 10px;
  width: 9em;
  padding: 1.5em 0.5em;
  /* margin: 1em;  */
}

.refer-button {
  background: #ffffff;
  border: 0;
  box-shadow: 0px 0px 10px rgba(84, 97, 122, 0.1);
  border-radius: 100px;
  font-weight: 800;
  width: 100%;
  color: #2e59dd;
  font-size: 0.7em;
  height: 2.5em;
}

.refer-body {
  font-size: 0.7em !important;
  color: #3c5e9b;
}
.refer-title {
  font-size: 0.9em !important;
  font-weight: 800;
  color: #23324e;
}
/* Collapsable vertical nav bar*/
.row.collapse {
  margin-left: -200px;
  left: 0;
  transition: margin-left 0.15s linear;
}

.row.collapse.show {
  margin-left: 0 !important;
}
.sup-style {
  font-size: 9px;
  color:white;
}

.sup-style-new {
  font-size: 0.6rem;
  color:red;
}

.df-row {
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: center;
}

.w-sidebar {
  width: 300px;
  max-width: 300px;
}
.row.collapsing {
  margin-left: -200px;
  left: -0.05%;
  transition: all 0.15s ease;
}

/* optional */
.vh-100 {
  min-height: 100vh;
}

/* optional for overlay sidebar on small screens */
@media (max-width: 768px) {
  .row.collapse,
  .row.collapsing {
    margin-left: 0 !important;
    left: 0 !important;
    overflow: visible;
  }

  .row > .sidebar.collapse {
    display: flex !important;
    margin-left: -100% !important;
    transition: all 0.3s linear;
    position: fixed;
    z-index: 1050;
    max-width: 0;
    min-width: 0;
    flex-basis: auto;
  }

  .row > .sidebar.collapse.show {
    margin-left: 0 !important;
    width: 100%;
    max-width: 100%;
    min-width: initial;
  }

  .row > .sidebar.collapsing {
    display: flex !important;
    margin-left: -10% !important;
    transition: all 0.2s linear !important;
    position: fixed;
    z-index: 1050;
    min-width: initial;
  }
}
