.nav {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 100%;
}

.heading {
  display: flex;
  flex-direction: column;
  color: #2f3eac;
  margin-right: auto;
}

.heading h2 {
  margin-bottom: 0 !important;
}

.paragraph {
  font-weight: 500;
  line-height: 1.5;
  margin-bottom: 0 !important;
}

.profil {
  height: 60px;
  width: 60px;
  cursor: pointer;
}
.profilMenu-link {
  color: inherit;
  &,
  &:hover,
  &:active,
  &:visited {
    text-decoration: none;
  }
}

.search {
  width: 255x;
  display: flex;
  align-items: center;
  border-radius: 10rem;
  margin: 0;
  background: #f5f7fa;
  padding-left: 15px;

  & button:hover,
  & button:active {
    background: none;
  }
}

.search img {
  height: 20px;
  width: 20px;
}

.searchField {
  font-family: "Poppins", sans-serif;
  font-size: 15px;
  font-weight: 400;
  color: #8ba3cb;
  border: none;
  background: none;
}

.searchField::placeholder {
  color: #8ba3cb;
}

.notifBtn {
  height: 50px;
  width: 50px;
  background: #f5f7fa;
  display: flex;
  align-items: center;
  justify-content: center;
}
.notifBtn img {
  height: 25px;
  width: 25px;
}

.badge {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0.6rem;
  color: white;
  background-color: red;
  padding: 1px 4px;
  border-radius: 3px;
  height: 1rem;
  width: 1rem;
}
