.AnchorLink {
  display: flex;
  align-items: center;
  text-align: left;
  gap: 12px;
  color: white;
  font-size: 16px;
  font-weight: 600;
  padding: 6px 12px;
  transition: all 0.3s;
  text-decoration: none;
}

.AnchorLink:hover,
.AnchorLink:active {
  color: #0A72C3;
  background-color: white;
  border-radius: 10px;
  text-decoration: none;
}

.AnchorLink svg {
  width: 24px;
  height: 24px;
  color: white;
  transition: all 0.3s;
}

.AnchorLink:hover svg,
.AnchorLink:active svg {
  color: #0A72C3;
}
