.no-padding {
  padding-inline: 0;
}
.no-padding-right {
  padding-right: 0;
}
.no-padding-left {
  padding-left: 0;
}
.centeredBodyCell div:last-child {
  text-align: center;
}
.centeredHeaderCell.no-padding {
  padding-inline: 0;
}
.centeredHeaderCell.no-padding-right {
  padding-right: 0;
}
.centeredHeaderCell div {
  text-align: center;
}
.cellProps {
  font-size: 12px;
  font-weight: 500;
  line-height: 20px;
  color: #1e1e1e;
}
