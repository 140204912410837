.main {
    padding: 0 0 1em 3em;
    background: rgb(248, 248, 248, 0.4);
}

.title {
    font-weight: 700;
    font-size: 32px;
    line-height: 48px;
    color: #2F3EAC;
}

.row-wrap {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 10px
}

.tile {
    padding: 30px;
    border-radius: 8px;
    border: 3px solid #F2F6FA;
    width: 200px
}