.topbar {
  height: 65px;
  padding: 10px 18px 10px 15px;
  background: #F2F6FA;
}

.img {
  border-radius: 50%;
  height: 1.5em;
  width: 1.5em;
}

.notification > .notification-center-header[data-v-5b8e1db6]  {
  color:#385A9F;
  background: #385A9F;
}

.item,
.img {
  margin-left: 1em;
  color:#385A9F;
  font-weight: bold;
}
.topbar-button {
  border: 0;
  box-shadow: none;
  background: none;
}
.topbar-button:focus {
  border: 0;
  box-shadow: none;
  background: none;
  outline: none;
}


.badge-notify[data-v-5b8e1db6] {
  background: #f05151;
  position: absolute;
  top: -8px;
  left: 13px;
}

.checkbox-round {
  border: 1px solid #ddd;
  appearance: none;
  -webkit-appearance: none;
  outline: none;
  cursor: pointer;
}

.checkbox-round:checked {
  background-color: rgb(47, 62, 172);
}

.submit-button {
  border: 3px solid transparent;
  padding: 10px;
  background: #2F3EAC;
  border-radius: 100px;
  width: 100px;
  color: white;
  transition: 0.3s;
}
.submit-button:focus {
  outline: none;
}
