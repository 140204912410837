.background {
  padding: 1.2em;
  background: rgb(248, 248, 248, 0.4);
}
.main {
  padding: 1.2em;
}
table .w-5 {
  width: 5%;
}
.table-background {
  background: #ffffff !important;
}
.title {
  font-weight: 900;
}
.table-header > th {
  font-weight: 800;
  font-size: 1.2em;
  text-align: left;
}
.row-name-style {
  font-size: 1.5em;
  font-weight: 400;
}
.div-pending-color {
  background: #fcf3e2;
  border-radius: 4px;
  color: #ca9160;
}
.div-complete-color {
  background: #44b480;
  border-radius: 4px;
  color: #ffffff;
}
.checkbox-incomplete {
  opacity: 1;
}
.checkbox-complete {
  opacity: 0.4;
}
.card-user-box {
  background: #ffffff;
  border-radius: 10px;
  border: 1px solid #2e59dd;
  width: 6em;
  height: 6em;
  padding: 1em;
  margin: 1.5em;
  display: inline-block;
}
.card-content-35 {
  height: 45%;
}
.card-name-bold {
  font-weight: 300;
}
.div-status {
  width: 6em;
  padding: 0.2em;
  font-size: 0.9em !important;
}
.message-box {
  background: #f3f8ff;
  border-radius: 10px;
  height: 100%;
  padding: 1.5rem;
}

.header-text {
  font-weight: 800;
  font-size: 1.1rem;
}

