.topbar-button {
    border: 0;
    box-shadow: none;
    background: none;
  }

.topbar-button:focus {
    border: 0;
    box-shadow: none;
    background: none;
    outline: none;
}

.badge {
  font-size: 0.6rem;
  color:white;
  background-color: red;
  padding: 1px 4px;
  border-radius: 3px;
}