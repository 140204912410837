a {
  text-decoration: none;
}
a:hover {
  text-decoration: none;
}

/* ::-webkit-scrollbar {
  display: none;
} */

input,
textarea {
  background-color: #eff1f7;
  color: #2f3eac;
  border: none;
  border-radius: 4px;
  padding: 12px 10px;
}

.main {
  padding: 32px 120px;
  min-height: 510px;
}

.row {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.frow {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.fcolumn {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.button {
  padding: 8px 32px;
  background: rgb(47, 62, 172);
  border-radius: 100px;
  color: white;
  font-weight: 700;
  cursor: pointer;
}

.white_button {
  padding: 8px 32px;
  color: rgb(47, 62, 172);
  border-radius: 100px;
  background: white;
  font-weight: 700;
  width: max-content;
  margin: auto;
}

.left-align {
  text-align: left;
}

.right-align {
  text-align: right;
}

.center-align {
  text-align: center;
}

.rectangle1 {
  width: 285px;
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0em;
  text-align: center;
}

.rectangle2 {
  padding: 20px 24px 20px 28px;
  width: 227px;
  height: 150px;
  color: rgb(47, 62, 172);
  border-radius: 20px;
  border: 1px solid rgb(47, 62, 172);
  font-weight: 700;
  font-size: 18px;
  line-height: 27px;
  cursor: pointer;
  margin-bottom: 10px;
}

.rectangle3 {
  padding: 20px 24px 20px 28px;
  width: 227px;
  height: 150px;
  background: rgb(47, 62, 172);
  color: white;
  border-radius: 20px;
  border: 1px solid rgb(47, 62, 172);
  font-weight: 700;
  font-size: 18px;
  line-height: 27px;
  box-shadow: inset 0px -6px 12px rgba(0, 0, 0, 0.25);
  cursor: pointer;
  margin-bottom: 10px;
}

.rectangle4 {
  width: 895px;
  height: 720px;
}
.rectangle4-1 {
  padding: 28px 80px 24px 60px;
  width: 895px;
  height: 212px;
  background: #385a9f;
  border-radius: 20px 20px 0px 0px;
  color: white;
}

.rectangle4-2 {
  width: 895px;
  height: 500px;
  color: rgb(47, 62, 172);
  background: #f4f7f9;
  border-radius: 0 0 20px 20px;
}

.logo {
  text-align: left;
  position: fixed;
}

.title {
  font-weight: bold;
  line-height: 66px;
}

.f-14 {
  font-size: 14px;
}

.f-16 {
  font-size: 16px;
}

.f-17 {
  font-size: 17px;
}

.f-20 {
  font-size: 20px;
}

.f-24 {
  font-size: 24px;
}

.f-30 {
  font-size: 30px;
}

.f-31 {
  font-size: 31px;
}
.f-40 {
  font-size: 40px;
}

.f-45 {
  font-size: 45px;
}

.f-50 {
  font-size: 50px;
}

.f-57 {
  font-size: 57px;
}

.f-70 {
  font-size: 70px;
}

.fw-450 {
  font-weight: 450;
}

.fw-500 {
  font-weight: 500;
}

.fw-b {
  font-weight: bold;
}

.mt-auto {
  margin: auto 0px;
}

.mt-132 {
  margin-top: 132px;
}

.mt-100 {
  margin-top: 100px;
}

.mt-40 {
  margin-top: 40px;
}

.mt-25 {
  margin-top: 25px;
}

.mt-16 {
  margin-top: 16px;
}

.mt-10 {
  margin-top: 10px;
}

.ml-auto {
  margin: 0px auto;
}

.ml-18 {
  margin-left: 18px;
}

.ml-10 {
  margin-left: 10px;
}

.ml-24 {
  margin-left: 24px;
}

.ml-150 {
  margin-left: 150px;
}

.ml-175 {
  margin-left: 175px;
}

.ml-230 {
  margin-left: 230px;
}

.ml-257 {
  margin-left: 257px;
}

.ml-345 {
  margin-left: 345px;
}

.ml-425 {
  margin-left: 425px;
}

.ml-1100 {
  margin-left: 1100px;
}

.mb-50 {
  margin-bottom: 50px;
}

.mb-10 {
  margin-bottom: 10px;
}

.mr-400 {
  margin-right: 400px;
}

.w-275 {
  width: 275px;
}

.w-680 {
  width: 680px;
}

.container {
  display: flex;
  flex-direction: column;
  height: 600px;
}

ul {
  list-style-type: none;
}

.text-input,
.password-input {
  background-color: rgba(56, 90, 159, 0.1);
  border-radius: 6px;
  color: #385a9f;
  font-size: 17px;
  border: 0;
  margin-bottom: 10px;
}

.text-input::placeholder,
.password-input::placeholder {
  color: #385a9f6c;
}

input:focus {
  outline: none;
}

.list-item {
  display: flex;
  margin-right: 10px;
  font-size: 24px;
}

.h-20 {
  height: 20px;
}

.h-35 {
  height: 35px;
}

.w-519 {
  width: 519px;
}

.w-300 {
  width: 300px;
}

.w-320 {
  width: 320px;
}

.w-250 {
  width: 250px;
}

.w-155 {
  width: 155px;
}

.w-208 {
  width: 208px;
}

.w-610 {
  width: 610px;
}

.w-250 {
  width: 250px;
}

.sign-up {
  background: #385a9f;
  border-radius: 30px;
  font-weight: bold;
  font-size: 18px;
  color: white;
  text-align: center;
  height: 41px;
  border-style: solid;
  border: none;
}

.send-button {
  background: #385a9f;
  border-radius: 30px;
  font-weight: bold;
  font-size: 18px;
  color: white;
  text-align: center;
  height: 41px;
  border-style: solid;
  border: none;
}

.hashtag-container {
  height: 85px;
  /* width: 800px; */
  display: flex;
  /* flex-wrap: wrap; */
}

.cta-container {
  border-radius: 30px;
  border-style: solid;
  border-color: #385a9f;
  width: 500px;
  margin: auto;
  padding: 20px 0 10px 0;
}

.cta-item {
  font-weight: 600;
  color: #385a9f;
  background-color: rgba(56, 90, 159, 0.1);
  border-radius: 20px;
  border: none;
  padding: 10px;
  width: 215px;
  margin: auto;
}

.top-arrow:after {
  content: " ";
  position: absolute;
  right: 5vw;
  top: -15px;
  border-top: none;
  border-right: 15px solid transparent;
  border-left: 15px solid transparent;
  border-bottom: 15px solid #3947a8;
}
