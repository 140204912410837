.background {
    padding: 1.2em;
    background: rgb(248, 248, 248, 0.4);
    /* height: 70%; */
  }
  .main {
    padding: 1.2em;
  }
  table .w-5 {
    width: 5%;
  }
  .table-background {
    background: #ffffff !important;
  }
  .title {
    font-weight: 900;
  }
  .table-header > th {
    font-weight: 800;
    font-size: 1.2em;
  }
  .div-status {
    width: 5.5em;
    padding: 0.3em;
  }
  .row-name-style {
    font-size: 1.5em;
    font-weight: 400;
  }
  .div-overdue-color {
    background: #e43f3f;
    border-radius: 4px;
    color: #ffffff;
  }
  .div-pending-color {
    background: #fcf3e2;
    border-radius: 4px;
    color: #ca9160;
  }
  .div-complete-color {
    background: #44b480;
    border-radius: 4px;
    color: #ffffff;
  }
  
  .checkbox-style {
    height: 1.5rem;
    width: 1.5rem;
    background: #ffffff;
    border: 1px solid #2e59dd;
    box-sizing: border-box;
    border-radius: 4px;
    border-width: 0.3em;
    cursor: pointer;
  }
  .checkbox-incomplete {
    opacity: 1;
  }
  .checkbox-complete {
    opacity: 0.4;
  }
  .message-box {
    background: #f3f8ff;
    border-radius: 10px;
    height: 100%;
    padding: 1.5rem;
  }
  
  .header-text {
    font-weight: 800;
    font-size: 1.1rem;
  }
  
  .fs-14{
    font-size: 14px;
    font-weight: normal;
  }

  .row {
    display: flex;
  }