.calendar-style {
  min-width: 320px;
  height: 1100px;
}
.helper-text-style {
  color: #0A72C3;
}
.dot {
  height: 4em;
  width: 4em;
  background-color: #f3f8ff;
  border-radius: 50%;
  display: inline-block;
}
.main {
  padding: 1.2em;
  background: rgb(248, 248, 248, 0.4);
}

.container-data-box {
  height: 7em;
  background: #ffffff;
  border: 1px solid #dfe0eb;
  box-sizing: border-box;
  border-radius: 8px;
}
.container-data-boost {
  height: 21em;
  background: #ffffff;
  border: 1px solid #dfe0eb;
  box-sizing: border-box;
  border-radius: 8px;
  padding: 2em;
}

.container-vertial-box {
  background: #f3f8ff;
  border-radius: 10px;
  height: 100%;
  width: 100%;
  /* float: right; */
  margin-left: auto;
  margin-top: 1em;
}
.box-text-title {
  font-size: 1.8rem;
  font-weight: 400;
  line-height: 150%;
}

.row-mt-space {
  margin-top: 4em !important;
}

.row-ml-space {
  margin-left: 1em !important;
}
.row-mr-space {
  margin-right: 1em !important;
}
.title {
  font-weight: 900;
}
.body-text {
  font-size: 0.9rem;
  font-weight: 400;
  line-height: 150%;
}
.div-container-style {
  height: 50vh;
  background: #ffffff;
}
@media (max-width: 768px) {
  .row-mr-space {
    margin-right: 0em !important;
  }
  .row-ml-space {
    margin-left: 0em !important;
  }
}

@media (max-width: 576px) {
  .input-container {
    max-width: 100% !important;
  }
}

@media (max-width: 768px) {
  .input-container {
    width: 100% !important;
  }
}

@media (max-width: 992px) {
  .input-container {
    max-width: 90% !important;
  }
}

@media (max-width: 1280px) {
  .input-container {
    width: 90% !important;
  }
}
@media (min-width: 1600px) {
  .input-container {
    max-width: 100% !important;
  }
}

.submit-button {
  border: 3px solid transparent;
  width: 100%;
  padding: 1em 1em;
  background: #0A72C3;
  border-radius: 100px;
  outline: none;
  color: #ffffff;
  font-weight: 800;
  font-size: 1rem;
  line-height: 1.4rem;
  white-space: nowrap;
  width: fit-content;
  text-align: center;
  transition: 0.3s;
}

.submit-button:focus {
  outline: none;
}
.submit-button:hover {
  border: 3px solid rgba(165, 185, 242, 1);
}

.input-submit {
  background: #f3f8ff;
  border: 1px solid rgba(46, 89, 221, 0.4);
  box-sizing: border-box;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.15);
  border-radius: 100px;
  padding: 1.6em 1em;
  line-height: 2rem;
}

.preview-placeholder {
  box-shadow: 3px 3px 5px rgba(0, 0, 0, 0.05);
  border: 1px solid #e1e8ed;
  width: 100%;
  height: 7.8rem;
}

.icon {
  height: 3rem;
  width: 3rem;
  transform: translate(-0.8rem, -0.8rem);
}

.icon-container {
  width: 3.4rem !important;
}

.post-delete-icon {
  padding: 0;
  border: none;
  background: none;
}

.responses-container {
  background: #F2F2F2;
  min-height:60px;
  margin:10px 0px;
}

.responses-element {
  margin-top: auto;
  margin-bottom: auto;
  margin-left:10px;
}

.refresh-button {
  border:none
}

.responses-text {
  border: none;
  background: #F2F2F2;
  width:95%;
  outline:none;
  resize:none;
  caret-color: #a1a1a1;
  font-size: 16px;
}

.refresh-icon {
  margin-right: 7px;
  top: 2px;
  animation: spin 1s infinite linear
}

.font-red{
  color: red;
}

@keyframes spin {
  from {
      transform: scale(1) rotate(0deg);
  }
  to {
      transform: scale(1) rotate(360deg);
  }
}

.hide-div {
  visibility: hidden;
  display: none;
}

.pr-15 {
  padding-right:15px
}

.max-width-80 {
  max-width: 80%;
}

.w-100 {
  width: 100%
}

.flex-row-reverse{
  display: flex;
  flex-direction: row-reverse;
}

.fs-20{
  font-size: 20px;
}

.fs-16{
  font-size: 16px;
}

.confirmation-message {
  font-size: large;
  font-weight: bold;
  color: #0A72C3;
}

.reminder {
  font-size: large;
  font-weight: bold;
  color: red;
}

.row {
  display: flex;
  flex-direction: row;
}

.col {
  display: flex;
  flex-direction: column;
}


/** Display allow notification dialog box */
.notification {
  color: #0A72C3;
}

.notification:hover{
  cursor: pointer;
  text-decoration: underline;
}
