input[type="checkbox"] {
  font: inherit;
  border: 1px solid #99a1ae;
  border-radius: 3px;
  width: 1.15em;
  height: 1.15em;
  transform: translateY(2px);
  display: grid;
  place-content: center;
  accent-color: #0A72C3;
}

button {
  cursor: pointer;
}

button:focus {
  outline: none !important;
  box-shadow: none !important;
  outline-style: none !important;
}

.main {
  padding: 0 0 1em 3em;
  background: rgb(248, 248, 248, 0.4);
}

.title {
  font-weight: 700;
  font-size: 32px;
  line-height: 48px;
  color: #0A72C3;
}

table {
  border-collapse: collapse;
  border: none;
}

thead {
  height: 40px;
  background: #e8eef6;
  border-radius: 8px;
}

tr {
  border-bottom: 1px solid #f2f6fa;
}

td {
  vertical-align: baseline;
  height: 10px;
}

textarea:focus,
input:focus {
  outline: none;
}

sup {
  color: red;
}

.text-input {
  background-color: rgba(56, 90, 159, 0.1);
  border-radius: 6px;
  color: #0A72C3;
  font-size: 16px;
  border: 0;
  margin-bottom: 10px;
  width: 100%;
  height: 48px;
  padding: 10px;
}

.prospect-profile {
  cursor: pointer;
  color: #0000ee;
}

.button {
  background: #0A72C3;
  border-radius: 30px;
  font-size: 15px;
  color: white;
  text-align: center;
  height: 37px;
  border-style: solid;
  border: none;
  width: max-content;
  font-weight: 500;
  padding: 0px 20px;
  gap: 8px;
}

.button-selected {
  background: #0A72C3;
  border-radius: 30px;
  font-size: 15px;
  color: white;
  text-align: center;
  height: 37px;
  border-style: solid;
  border: none;
  width: max-content;
  font-weight: 500;
  padding: 8px 20px;
  gap: 8px;
  cursor: pointer;
}

.button-unselected {
  background: #e1e7f0;
  border-radius: 30px;
  font-size: 15px;
  color: black;
  text-align: center;
  height: 37px;
  border-style: solid;
  border: none;
  width: max-content;
  font-weight: 500;
  padding: 8px 20px;
  gap: 8px;
  cursor: pointer;
}

.button-locked {
  background: #e1e7f0;
  border-radius: 30px;
  font-size: 15px;
  color: black;
  text-align: center;
  height: 37px;
  border-style: solid;
  border: none;
  width: max-content;
  font-weight: 500;
  padding: 8px 20px;
  gap: 8px;
  display: flex;
  align-items: center;
}

.delete-button {
  background: white;
  border: none;
  width: 36px;
}

.refresh-button {
  background: white;
  border: none;
}

.tooltip {
  padding: 6px;
  border-radius: 10px;
  font-size: 12px;
}

.mw-150 {
  max-width: 150px;
}

.mw-200 {
  max-width: 200px;
}

.spin {
  margin-right: 7px;
  top: 2px;
  animation: spin 1s infinite linear;
}

@keyframes spin {
  from {
    transform: scale(1) rotate(0deg);
  }
  to {
    transform: scale(1) rotate(360deg);
  }
}

.fs-20 {
  font-size: 20px;
}

.fs-16 {
  font-size: 16px;
}

.flex-row {
  display: flex;
  flex-direction: row;
}

.responses-container {
  min-height: 80px;
  margin: 0 0 10px 0px;
  border: 1px solid #e1e7f0;
  border-radius: 8px;
}

.responses-element {
  margin-top: auto;
  margin-bottom: auto;
  margin-left: 10px;
}

.responses-text {
  border: none;
  width: 100%;
  outline: none;
  resize: none;
  caret-color: #a1a1a1;
  font-size: 14px;
  height: max-content;
}

.selected-rect {
  display: flex;
  justify-content: space-between;
  gap: 5px;
  padding: 12px 16px;
  background: rgb(232, 238, 246);
  border-radius: 8px;
  cursor: pointer;
  font-weight: 700;
}

.rect {
  display: flex;
  justify-content: space-between;
  gap: 5px;
  padding: 12px 16px;
  border-radius: 8px;
  cursor: pointer;
}

.rect-lock {
  display: flex;
  gap: 5px;
  padding: 12px 16px;
  border-radius: 8px;
}

.spin {
  animation: spin 2s infinite linear;
}

.hover-container {
  width: 250px;
  padding: 9px;
  border: none;
  border-radius: 6px;
  background-color: #ffffff;
  font-size: 12px;
  box-shadow: 0px 0px 6px rgb(0 0 0 / 10%);
}

.hover-container::after {
  content: " ";
  position: inherit;
  top: 100%; /* At the bottom of the tooltip */
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: white transparent transparent transparent;
}

.hide {
  display: none;
}

.box-container {
  flex: 1;
}
